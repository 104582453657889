import React from 'react';
import Calculator from '../../pagecomponents/Pickingcalculator';
import './PickingTab.css';

function Picking() {
  return (
    <div className='pickingTab'>
      <Calculator />
    </div>
  );
};

export default Picking