import React from 'react';

function Pickingcalculator() {

  /*
    destructing your picking info from the selected planting
    //collect info from form
    ///on submit run a stateful change (setApp({type: savePickingInfo, payload: form data}))
  */

  return (
    <div >Pickingcalculator</div>
  );
};

export default Pickingcalculator