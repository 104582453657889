import React from 'react'
import './SprayingTab.css';

function Spraying() {
  return (
    <div className='sprayingTab'>
      Spraying Tab
    </div>
  );
};

export default Spraying