import React from 'react';
import './AccountTab.css';

function Account() {
  return (
    <div className='accountTab'>
      account
    </div>
  );
};

export default Account

